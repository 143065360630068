import { isNullOrUndefined } from 'util';

/**
 * Format percentage as a grade text e.g. "Outstanding (9.5)".
 * @param percentage
 */
export function getGradeString(percentage: number | undefined | null, options?: { numberOnly?: boolean, nameOnlyOnGradeChange?: boolean }): string {
    if (isNullOrUndefined(percentage)) {
        return 'N/A';
    }

    let gradeNumber = 1 + (percentage / 10.0);

    // this is to stop the rounding giving us the wrong gradeName at the highest of each level 
    // e.g. 75.9 gives us Good 9 when it should give us Outstanding 9 because it gets rounded up 
    // to 9 in the return value but calculates as 8.59 which is less than 9 for the gradeName
    gradeNumber = parseFloat(gradeNumber.toFixed(1)); 

    let gradeName = 'N/A';
    if (gradeNumber < 4) {
        gradeName = 'Emerging';
    } else if (gradeNumber < 6) {
        gradeName = 'Developing';
    } else if (gradeNumber < 9) {
        gradeName = 'Secure';
    } else if (gradeNumber < 10.9) {
        gradeName = 'Embedded';
    } else /* gradeNubmber >= 10.9 */ {
        gradeName = 'Embedded';
        gradeNumber = 10.9; // Don't allow anything above 10.9 to be displayed. i.e. we don't want a "Outstanding (11)" so force anything 10.9 and above to show as 10.9.
    }

    // If we only want the number, then return it.
    if (options && options.numberOnly) {
        return gradeNumber.toFixed(1);
    }

    // If we only want the grade name when it changes, then cope with that
    if (options && options.nameOnlyOnGradeChange) {
        // Only include the name if we are on specific numbers
        if (gradeNumber === 1.0 || gradeNumber === 4.0 || gradeNumber === 6.0 || gradeNumber === 9.0) {
            return `${gradeName} (${gradeNumber.toFixed(1)})`;
        }

        return gradeNumber.toFixed(1);
    }

    // Otherwise return grade name and number.
    return `${gradeName} (${gradeNumber.toFixed(1)})`;
}